import {Link, useNavigate, useParams} from "react-router-dom";
import React, {createRef, useEffect, useRef, useState} from "react";
import axios from "axios";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {CardBody, CardFooter, CardHeader, DefaultCard} from "../../../../pages/PageStyleBox";
import {Button, Col, message, Row, Tooltip} from "antd";
import {InfoCircleOutlined, RightOutlined, SaveOutlined, SyncOutlined} from "@ant-design/icons";
import {Form, Table} from "reactstrap";
import {httpStatus} from "../../../../lib/createRequestSaga";
import ExcelJS from "exceljs";
import style from './style.module.scss'
import DefaultLoading from "../../DefaultLoading";
import {Notice} from "../../../../container/build-tool/StyleBox";
import _ from "lodash";
import {usePrompt} from "../../../../lib/Blocker";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

const RolesPrivileges = () => {
    const params = useParams();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [roles, setRoles] = useState(null);
    const [privileges, setPrivileges] = useState(null);
    const [savedRolePrivileges, setSavedRolePrivileges] = useState("init");
    const [loading, setLoading] = useState(false);
    const [change, setChange] = useState(false);

    //변경 감지 후 확인 창 띄우기
    usePrompt(change, t);

    const rolePrivilegesRef = useRef([]);

    useEffect( () => {
        (async () => {
            try {
                const {status, data} = await axios.get(`/api/builder/studies/${params.sid}/roles-privileges`);

                if (status === httpStatus.ok) {
                    //set Data
                    setRoles(data?.roles);
                    setPrivileges(data?.privileges);
                    setSavedRolePrivileges(data?.savedRolePrivileges);
                }
            } catch (e) {
                errorHandle(e, navigate, `/studies/${params.sid}/dashboard`);
            }
        })();
    }, [])


    useEffect(() => {
        if ((roles !== null && roles !== undefined) && (privileges !== null && privileges !== undefined)) {
            if (rolePrivilegesRef.current.length !== roles.length * privileges.length) {
                // Add or Remove refs
                rolePrivilegesRef.current = Array(roles.length * privileges.length).fill().map((_, i) => rolePrivilegesRef.current[i] || createRef());
            }
        }

    }, [roles, privileges]);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            //rolePrivileges의 ref값을 가져와서 checked된 값만 세팅함.
            const body = {
                oldRolePrivileges: savedRolePrivileges,
                rolePrivileges: rolePrivilegesRef.current.filter(rp => rp.current.checked === true).map(rp => rp.current.defaultValue),
            };

            const {status, data} = await axios({
                url: `/api/builder/studies/${params.sid}/roles-privileges`,
                data: body,
                method: 'post'
            })

            if (status === httpStatus.ok) {
                //message
                message.success(data?.message);
                setSavedRolePrivileges(data?.savedRolePrivileges);
                setChange(false);
            }
        } catch (e) {
            errorHandle(e);
        }
        setLoading(false);
    }

    const onChangedCheck = () => {
        //변경 여부 확인.
        const rolePrivileges = rolePrivilegesRef.current.filter(rp => rp.current?.checked === true).map(rp => rp.current?.defaultValue);
        setChange(!_.isEqual(savedRolePrivileges.sort(), rolePrivileges.sort()));
    };

    const onSync = async () => {
        setLoading(true);
        const originSavedRolePrivileges = _.cloneDeep(savedRolePrivileges);
        try {
            setSavedRolePrivileges('init');
            const {status, data} = await axios.post(`/api/builder/studies/${params.sid}/roles-privileges/sync`);

            if (status === httpStatus.ok) {
                //Sponsor Data Sync
                message.success(data?.message);
                setSavedRolePrivileges(data?.savedRolePrivileges);
                setChange(false);
            }
        } catch (e) {
            errorHandle(e);
            setSavedRolePrivileges(originSavedRolePrivileges);
        }
        setLoading(false);
    }

    const handleExport = async () => {
        // 1. 테이블 헤더 데이터 (Role)
        const headers = ["Privileges", ...roles.map((role) => role.shortName)];

        // 2. 테이블 본문 데이터 (Privilege와 Role 체크 상태)
        const data = privileges.map((privilege) => {
            const row = [privilege.description];
            roles.forEach((role) => {
                const key = `${role.id},${privilege.id}`;
                row.push(savedRolePrivileges.includes(key) ? "✔️" : "");
            });
            return row;
        });

        // 3. 전체 데이터 합치기
        const worksheetData = [headers, ...data];

        // 4. 워크북 및 워크시트 생성
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet("Role&Privilege");

        // 5. 데이터 삽입
        worksheetData.forEach((row, rowIndex) => {
            const newRow = worksheet.addRow(row);

            // 6. 헤더 스타일 적용
            if (rowIndex === 0) {
                newRow.eachCell((cell) => {
                    cell.font = { bold: true }; // 볼드체
                    cell.fill = {
                        type: "pattern",
                        pattern: "solid",
                        fgColor: { argb: "D9D9D9" }, // 회색
                    };
                    cell.alignment = { horizontal: "center", vertical: "middle" }; // 가운데 정렬
                });
            }
        });

        // 7. 열 너비 설정
        worksheet.columns = worksheetData[0].map((_, colIndex) => {
            const maxLength = worksheetData.reduce((max, row) => {
                const cell = row[colIndex] || ""; // 셀이 비어 있을 경우 빈 문자열 처리
                return Math.max(max, cell.toString().length);
            }, 0);
            return { width: maxLength + 2 }; // 여유 공간 추가
        });

        // 8. 파일 다운로드
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "Role&Privilege_" + dayjs().format("YYYYMMDDHHmmss") + ".xlsx";
        link.click();

        // URL 해제
        URL.revokeObjectURL(url);
    };

    return (
        <>
            {
                (roles === null || privileges === null || savedRolePrivileges === 'init') &&
                <DefaultLoading/>
            }
            {
                roles !== null && privileges !== null && savedRolePrivileges !== "init" &&
                <DefaultCard>
                    <CardHeader>
                        <Row>
                            <Col span={18}>
                                <h3><RightOutlined className={'mr-2'}/>Role & Privilege</h3>
                            </Col>
                            <Col span={6} className={'text-right'}>
                                <Button type={'default'} onClick={handleExport} >
                                    Export to Excel
                                </Button>
                                {
                                    (
                                        (savedRolePrivileges instanceof Array && savedRolePrivileges?.length <= 0)
                                        && (roles instanceof Array && roles.length > 0)
                                    ) &&
                                    <Button type={'default'} loading={loading} icon={<SyncOutlined/>} onClick={onSync}>
                                        Sync
                                        <Tooltip title={
                                            <>
                                                <div>{t('studies.retrieve.role.privilege.info1')}</div>
                                                <div>{t('studies.retrieve.role.privilege.info2')}</div>
                                            </>
                                        }>
                                            <InfoCircleOutlined/>
                                        </Tooltip>
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </CardHeader>
                    <Form onSubmit={onSubmit}>
                        <CardBody>
                            {
                                roles?.length > 0 ?
                                    (
                                        <Table bordered hover>
                                            <thead>
                                            <tr className={style.headerSticky}>
                                                <th className={`${style.backslash} stop-dragging`}>
                                                    <div>Role</div>
                                                    Privilege
                                                </th>
                                                {
                                                    roles.map((role, roleIdx) => (
                                                        <th key={roleIdx} className='stop-dragging width-150'
                                                            style={{textAlign: 'center', verticalAlign: 'middle'}}
                                                            scope={'row'}>
                                                            <div>{role.shortName}</div>
                                                        </th>
                                                    ))
                                                }
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                privileges.map((privilege, privilegeIdx) => (
                                                    <tr key={privilegeIdx} className={'stop-dragging'}>
                                                        <th style={{verticalAlign: 'middle'}}>
                                                            {privilege?.description}
                                                        </th>
                                                        {
                                                            roles.map((role, roleIdx) => (
                                                                <td key={roleIdx} className='text-center'>
                                                                    <input type={'checkbox'}
                                                                           onClick={onChangedCheck}
                                                                           className={style.roleCheckbox}
                                                                           ref={rolePrivilegesRef.current[(privilegeIdx * roles.length) + roleIdx]}
                                                                           value={`${role.id},${privilege.id}`}
                                                                           defaultChecked={savedRolePrivileges != null && savedRolePrivileges.includes(`${role.id},${privilege.id}`)}
                                                                    />
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </Table>
                                    ) : (
                                        <Notice>
                                            <h2 className={'mt-2'}>
                                                <span className={'mr-4'}>{t('studies.no.registered.role.info')}</span>
                                                <Link to={`/studies/${params.sid}/roles`}>
                                                    <span className='text-info'>[<i className='fa fa-external-link'/>{t('studies.move.to.role.menu')}]</span>
                                                </Link>
                                            </h2>
                                        </Notice>
                                    )
                            }
                        </CardBody>
                        {
                            (roles?.length > 0 && change) &&
                            <CardFooter className='text-right'>
                                <Button loading={loading} icon={<SaveOutlined/>} type={'primary'} htmlType={'submit'}>Save</Button>
                            </CardFooter>
                        }
                    </Form>
                </DefaultCard>
            }
        </>
    )
}

export default RolesPrivileges;
