import MandatoryBtn from "./build-form/MandatoryBtn";
import ConditionBtn from "./build-form/ConditionBtn";
import {ItemGroupExtra, ItemGroupOptionDiv} from "../StyleBox";
import React, {useState} from "react";
import RemoveBtn from "./build-form/RemoveBtn";
import {isEnvProduction, isExcludeOID} from "../../../lib/ODMUtils";
import {useSelector} from "react-redux";

const ItemGroupOption = ({metaData, itemGroup, formDef, setFormDef, itemRefMap, setItemRefMap, index}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    const [conditionVisible, setConditionVisible] = useState(false);
    const [removeVisible, setRemoveVisible] = useState(false);

    const getVisible = () => {
        return (conditionVisible || removeVisible);
    }

    return (
        <>
            <ItemGroupOptionDiv className={'ml-3'} visible={getVisible()}>
                <MandatoryBtn metaData={metaData} data={itemGroup} formDef={formDef} setFormDef={setFormDef}
                              index={index} defType={"itemGroup"}/>
                {/*Mandatory 관련 없이 Condition 걸리도록 수정 2025-01-22 #1693*/}
                <ConditionBtn metaData={metaData} data={itemGroup} formDef={formDef} setFormDef={setFormDef}
                              index={index} defType={"itemGroup"}
                              visible={conditionVisible} setVisible={setConditionVisible}/>
            </ItemGroupOptionDiv>
            <ItemGroupExtra visible={getVisible()}>
                {
                    (!isExcludeOID(itemGroup.itemGroupOID) && !isProd) &&
                    <RemoveBtn data={itemGroup} formDef={formDef} setFormDef={setFormDef}
                               itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                               index={index} defType={"itemGroup"}
                               visible={removeVisible} setVisible={setRemoveVisible} />
                }
            </ItemGroupExtra>
        </>
    )

}

export default ItemGroupOption;