import {ProtocolRefControlBlock} from "../StyleBox";
import React, {useState} from "react";
import MandatoryBtn from "./schedule/MandatoryBtn";
import ConditionBtn from "./schedule/ConditionBtn";
import RemoveBtn from "./schedule/RemoveBtn";
import {isExcludeOID} from "../../../lib/ODMUtils";

const ProtocolRefControlOption = ({metaData, defOID, refInfo, refItem, refType, onDeleteRef, toggleMandatory, onChangeCondition, isProd}) => {

    const [conditionVisible, setConditionVisible] = useState(false);
    const [removeVisible, setRemoveVisible] = useState(false);

    const getVisible = () => {
        return (conditionVisible || removeVisible);
    }
    return (
        <ProtocolRefControlBlock visible={getVisible()}>
            <MandatoryBtn defOID={defOID} refInfo={refInfo} refItem={refItem} toggleMandatory={toggleMandatory} isProd={isProd} />
            {/*{*/}
            {/*    refItem.mandatory === 'YES' &&*/}
            {/*    <ConditionBtn defOID={defOID} refItem={refItem} refInfo={refInfo} onChangeCondition={onChangeCondition} isProd={isProd}*/}
            {/*                  metaData={metaData} visible={conditionVisible} setVisible={setConditionVisible} />*/}
            {/*}*/}
            {
                !(isProd || isExcludeOID(refInfo.oid)) &&
                <RemoveBtn refInfo={refInfo} defOID={defOID} refType={refType} visible={removeVisible} setVisible={setRemoveVisible} onDeleteRef={onDeleteRef} />
            }
        </ProtocolRefControlBlock>
    )

}

export default ProtocolRefControlOption;