import {Button, Card, Checkbox, Col, Descriptions, Row, Spin, Table, Tag} from "antd";
import React, {useEffect, useState} from "react";
import style from './style.module.scss'
import {LoadingOutlined, RightOutlined} from "@ant-design/icons";
import {useNavigate, useParams} from "react-router-dom";
import axios from "axios";
import {errorHandle} from "../../../../lib/BuilderUtils";
import {Badge} from "reactstrap";
import {Notice} from "../../../../container/build-tool/StyleBox";
import styled from "styled-components";
import PerfectScrollbar from "react-perfect-scrollbar";
import {withTranslation} from "react-i18next";

const Dashboard = ({t}) => {

    const params = useParams();
    const navigate = useNavigate();
    const [edcStudyInfo, setEdcStudyInfo] = useState(null);
    const [recentCRFVersion, setRecentCRFVersion] = useState(null);
    const [crfVersions, setCrfVersions] = useState(null);
    const [actionLink, setActionLink] = useState(null);
    const [action, setAction] = useState(null);
    const [basicDefinition, setBasicDefinition] = useState(null);
    const [precondition, setPrecondition] = useState(null);
    const [preconditionCnt, setPreconditionCnt] = useState(null);
    const [saeTemplate, setSaeTemplate] = useState(null);

    const IntroduceContent = styled.div`
      position: relative;
      overflow: hidden;
      padding: 1.5rem;
      width: 100%;
      margin: 0 0 4rem 0;
    `;

    const crfColumns = [
        {
            title: 'No',
            render: (row, record, index) => {
                return crfVersions?.length - crfVersions.indexOf(row);
            },
        },
        {
            title: 'name',
            dataIndex: 'name',
        },
        {
            title: 'Version',
            render: (row, record, index) => {
                return `${row?.major}.${row?.patch}`
            }
        },
        {
            title: 'Created Date',
            dataIndex: 'createdDate'
        },
        {
            title: 'Last Modified Date',
            dataIndex: 'lastModifiedDate'
        },
    ]


    useEffect(() => {
        //GET Request
        (async () => { //비동기를 동기식으로 응답 받을 때 까지 대기.
            try {
                //REST API Request
                const {data} = await axios.get(`/api/builder/studies/${params.sid}/study-dashboard`);
                //State Setting
                setEdcStudyInfo(data?.edcStudyInfo);
                setRecentCRFVersion(data?.recentCRFVersion);
                setCrfVersions(data?.crfVersions);
                setActionLink(data?.actionLink)
                setAction(data?.action);
                setBasicDefinition(data?.basicDefinition);
                setPrecondition(data?.preconditionList);
                setPreconditionCnt(data?.preconditionCnt);
                setSaeTemplate(data?.saeTemplate);
            } catch (e) {
                errorHandle(e, navigate,'/studies');
            }
        })()
    }, []);

    const generateNumber = ({length, leftPadWithZero, initialNumber, autoGenerated}) => {
        let strNumber = '';

        if(autoGenerated) {
            strNumber = `${initialNumber}`;
            if(leftPadWithZero && length > strNumber?.length) {
                const repeat = length - strNumber?.length;
                for(let i=0; i<repeat; i++) { strNumber = '0' + strNumber; }
            }
        } else {
            if (length == 2) {
                strNumber = (leftPadWithZero ? '0' : '') + '1';
            } else if (length == 3) {
                strNumber = (leftPadWithZero ? '00' : '') +'1';
            } else if (length == 4) {
                strNumber = (leftPadWithZero ? '000' : '') + '1';
            } else if (length == 5) {
                strNumber = (leftPadWithZero ? '0000' : '') + '1';
            }
        }
        return strNumber;
    };


    const BasicDefinitionPreview = () => {
        const {screeningNumberDef, randomNumberDef} = basicDefinition;
        const seq = RegExp(/\${Seq}/g);
        let format = '';

        //Random Number 설정
        if (randomNumberDef != null && seq.test(randomNumberDef.format)) {
            format = randomNumberDef?.format.replace(seq, generateNumber(randomNumberDef));
        }

        return (
            <>
                {
                    (basicDefinition !== null && (screeningNumberDef !== null || randomNumberDef !== null)) ?(
                        <>
                            {
                                screeningNumberDef !== null &&
                                <Card title={'Screening Number'} type={'inner'} styles={{body:{padding:0}}}>
                                    <Descriptions bordered>
                                        <Descriptions.Item bordered>
                                            <h3>
                                                <Badge color={'info'}
                                                       className={'mr-1'}>{screeningNumberDef?.prefix}</Badge>
                                                <Badge color={'default'}
                                                       className={'mr-1'}>{screeningNumberDef?.siteCode ? '999' : ''}</Badge>
                                                <Badge color={'info'}
                                                       className={'mr-1'}>{screeningNumberDef?.suffix}</Badge>
                                                <Badge color={'default'}
                                                       className={'mr-1'}>{generateNumber(screeningNumberDef)}</Badge>
                                            </h3>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions bordered>
                                        <Descriptions.Item label={'Auto generate'} labelStyle={{width: '30%'}}>
                                            <Checkbox checked={screeningNumberDef?.autoGenerated || false} style={{cursor:'default !important'}} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label={'Left pad string with zeros'} labelStyle={{width: '30%'}}>
                                            <Checkbox checked={screeningNumberDef?.leftPadWithZero || false} style={{cursor:'default !important'}} />
                                        </Descriptions.Item>
                                        <Descriptions.Item label={'Initial Number'} labelStyle={{width:'30%'}}>
                                            <span>{(screeningNumberDef?.autoGenerated)?screeningNumberDef?.initialNumber:'-'}</span>
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            }
                            {(screeningNumberDef!=null&&randomNumberDef!=null)&&(<hr />)}
                            {
                                randomNumberDef != null &&
                                <Card title={'Random Number'} type={'inner'} styles={{body:{padding:0}}}>
                                    <Descriptions bordered>
                                        <Descriptions.Item bordered>
                                            <h3>
                                                <span className={'badge badge-success mr-1'}>{format}</span>
                                            </h3>
                                        </Descriptions.Item>
                                    </Descriptions>
                                    <Descriptions bordered>
                                        <Descriptions.Item label={'Left pad string with zeros'} labelStyle={{width: '30%'}}>
                                            <Checkbox checked={randomNumberDef?.leftPadWithZero || false} style={{cursor:'default !important'}} />
                                        </Descriptions.Item>
                                    </Descriptions>
                                </Card>
                            }
                        </>
                    ): (
                        <Notice style={{height:'12.25rem', flexDirection:'column'}}>
                            <h5 className='text-warning'><i className='fa fa-exclamation-circle' />{t('studies.no.registered.basic.def.info')}</h5>
                            <Button type={'link'} onClick={() => navigate(`/studies/${params.sid}/basic-definition`)}>
                                {t('studies.basic.def.shortcut')}<i className='ml-2 fa fa-external-link' />
                            </Button>
                        </Notice>
                    )
                }
            </>
        )
    };

    return (
        <>
            {
                edcStudyInfo == null &&
                <>
                    <Row gutter={[12, 12]} className={'ml-4'}>
                        <Col span={24}>
                            <h3><RightOutlined className={'mr-2'}/>Dashboard</h3>
                        </Col>
                    </Row>
                    <div className={style.absoluteCenter}>
                        <div className='text-center'>
                            <Spin size="large" indicator={<LoadingOutlined style={{fontSize: 64}} spin/>} />
                        </div>
                    </div>
                </>
            }
            {
                edcStudyInfo != null &&
                <>
                    <Row gutter={[12, 12]} className={'ml-4'}>
                        <Col span={24}>
                            <h3><RightOutlined className={'mr-2'}/>Dashboard</h3>
                        </Col>
                    </Row>
                    <Row gutter={[12, 12]} className={'m-4'}>
                        <Col span={'12'}>
                            <div className={style.infoBackground}>
                                <Descriptions layout={'vertical'} title={<span><i className={`fa fa-${edcStudyInfo.studyLocked ? 'times-circle-o' : 'circle-o'} mr-2`}/>Study Info</span>} bordered>
                                    <Descriptions.Item span={0.25} label={t('column.study.name')}>
                                        {edcStudyInfo.study.name}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={0.25} label={t('column.protocol.name')}>
                                        {edcStudyInfo.study?.protocolName}
                                    </Descriptions.Item>
                                    <Descriptions.Item span={0.25} label={t('column.phase')}>
                                        {edcStudyInfo?.phase}
                                    </Descriptions.Item>
                                    {
                                        edcStudyInfo?.subjectLimitType.key == 'EDC_STUDY_LIMIT' &&
                                        <Descriptions.Item span={0.25} label={t('column.subject.total.count')}>
                                            {edcStudyInfo?.totalSubjectCount||''}
                                        </Descriptions.Item>
                                    }
                                </Descriptions>
                                <Descriptions layout={'vertical'} bordered className={'mt-3'}>
                                    <Descriptions.Item label={t('column.study.description')}>
                                        <p>{edcStudyInfo.study.description}</p>
                                    </Descriptions.Item>
                                </Descriptions>
                            </div>
                        </Col>
                        <Col span={'12'}>
                            <div className={`${style.infoBackground}`}>
                                <Descriptions title={<span><i className="fe fe-file-text mr-2" />CRF Versions</span>} bordered>
                                    <Descriptions.Item label={'Recent CRF'} labelStyle={{width:'10rem'}}>
                                        {
                                            (recentCRFVersion !== null && recentCRFVersion !== undefined)? (
                                                <Button type={'link'} style={{textDecoration:'underline'}}
                                                        onClick={() => navigate(`/studies/${params.sid}/v/${recentCRFVersion.id}/${actionLink||"forms"}`)}>
                                                    <h4 className='d-flex align-items-center justify-content-center'>
                                                        {recentCRFVersion?.name} <Tag className={'ml-2'} color={"processing"}>{recentCRFVersion?.major}.{recentCRFVersion?.patch}</Tag> - {action ||'NO ACTION'}
                                                    </h4>
                                                </Button>
                                                ) : (
                                                    <h6 className='d-flex align-items-center justify-content-center'>
                                                        {t('studies.no.recent.history')}&nbsp;
                                                        <Button type={'link'} onClick={() => navigate(`/studies/${params.sid}/versions`)}>
                                                            {t('studies.move.crf-version-list')} <i className='ml-2 fa fa-external-link' />
                                                        </Button>
                                                    </h6>
                                            )
                                        }
                                    </Descriptions.Item>
                                </Descriptions>
                                <Table columns={crfColumns} pagination={false}
                                       dataSource={crfVersions} rowKey={'id'}
                                       loading={crfVersions === null}>
                                </Table>
                            </div>
                        </Col>
                        <Col span={'12'}>
                            <div className={`${style.infoBackground}`}>
                                <Descriptions title={<span><i className="fa fa-search mr-2"/>Basic Definition</span>} bordered />
                                <BasicDefinitionPreview />
                            </div>
                        </Col>
                        {/*<Col span={'12'}>*/}
                        {/*    <div className={`${style.infoBackground}`}>*/}
                        {/*        <Descriptions title={*/}
                        {/*            <>*/}
                        {/*                <span className={'mr-4'}><i className='fa fa-list-ul mr-2' />Precondition</span>*/}
                        {/*                {*/}
                        {/*                    preconditionCnt > 0 &&*/}
                        {/*                    <Tag>Total Count : {preconditionCnt}</Tag>*/}
                        {/*                }*/}
                        {/*            </>*/}
                        {/*        } />*/}
                        {/*        {*/}
                        {/*            precondition?.length > 0 ? (*/}
                        {/*                <Table bordered dataSource={precondition} pagination={false} rowKey={'action'} >*/}
                        {/*                    <Table.Column title={'Action'} align={'center'} dataIndex={'action'} key={'action'} />*/}
                        {/*                    <Table.Column title={'Precondition'} align={'center'} dataIndex={'precondition'} key={'precondition'}*/}
                        {/*                                  render={(value) => {*/}
                        {/*                                      const values = value.split(',');*/}
                        {/*                                      return (*/}
                        {/*                                          <>*/}
                        {/*                                              {*/}
                        {/*                                                  values.map(value => (*/}
                        {/*                                                      <Tag>{value}</Tag>*/}
                        {/*                                                  ))*/}
                        {/*                                              }*/}
                        {/*                                          </>*/}
                        {/*                                      )*/}
                        {/*                                  }} />*/}
                        {/*                </Table>*/}
                        {/*            ) : (*/}
                        {/*                <Notice style={{height:'12.25rem', flexDirection:'column'}}>*/}
                        {/*                    <h5 className='text-warning'><i className='fa fa-exclamation-circle' />{t('studies.no.registered.precondition.info')}</h5>*/}
                        {/*                    <Button type={'link'} onClick={() => navigate(`/studies/${params.sid}/precondition`)}>*/}
                        {/*                        {t('studies.precondition.shortcut')}<i className='ml-2 fa fa-external-link' />*/}
                        {/*                    </Button>*/}
                        {/*                </Notice>*/}
                        {/*            )*/}
                        {/*        }*/}
                        {/*    </div>*/}
                        {/*</Col>*/}
                        <Col span={'12'} hidden={!edcStudyInfo?.useSAEReport}>
                            <div className={`${style.infoBackground}`}>
                                <Descriptions title={<span><i className="fa fa-file-text-o mr-2" />SAE Report - Email Template</span>} />
                                {
                                    saeTemplate !== null ? (
                                        <Card title={saeTemplate?.title}>
                                            <Row gutter={[20, 0]}>
                                                <Tag className={'mb-2'}><h4 className={'m-0'}>Email Receiver</h4></Tag>
                                                <Col span={24} className='pt-2 d-flex justify-content-start' style={{height: '5rem', border:'dashed 1px'}}>
                                                    <PerfectScrollbar style={{width:'100%'}}>
                                                    {
                                                        (saeTemplate?.emailReceiverList !== null && saeTemplate?.emailReceiverList?.length > 0) &&
                                                        (
                                                            saeTemplate.emailReceiverList.map((receiver, index) => (
                                                                <div key={index}>
                                                                    <Tag className={'mr-2'}>{receiver?.receiveType}</Tag>
                                                                    <Tag className={'mr-2'}>{`${receiver?.userName} [${receiver?.email}]`}</Tag>
                                                                    <Tag>{[...receiver?.selectedEnvs].join(',')}</Tag>
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                    </PerfectScrollbar>
                                                </Col>
                                                <Col span={24}>
                                                    <IntroduceContent dangerouslySetInnerHTML={{__html: saeTemplate?.content}}/>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ) : (
                                        <Notice style={{height:'12.25rem', flexDirection:'column'}}>
                                            <h5 className='text-warning'><i className='fa fa-exclamation-circle' />{t('studies.no.content.sae-email-template')}</h5>
                                            <Button type={'link'} onClick={() => navigate(`/studies/${params.sid}/email-notice`)}>
                                                {t('studies.setting.sae.report.shortcut')}<i className='ml-2 fa fa-external-link' />
                                            </Button>
                                        </Notice>
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </>
            }
        </>
    )

}

export default withTranslation()(Dashboard);
