import MandatoryBtn from "./build-form/MandatoryBtn";
import ConditionBtn from "./build-form/ConditionBtn";
import {ItemOptionDiv, RepeatingItemOptionDiv} from "../StyleBox";
import React, {useState} from "react";
import UnitsBtn from "./build-form/UnitsBtn";
import MethodBtn from "./build-form/MethodBtn";
import RemoveBtn from "./build-form/RemoveBtn";
import {isEnvProduction, isExcludeOID} from "../../../lib/ODMUtils";
import ValueListBtn from "./build-form/ValueListBtn";
import {useSelector} from "react-redux";
import KeySequenceBtn from "./build-form/KeySequenceBtn";


const OptionButtons = ({
                           metaData, itemRef, itemGroup, itemRefMap, setItemRefMap,
                           index, isValueList, setIsValueList,
                           isEnableUnit, isProd,
                           conditionVisible, setConditionVisible,
                           unitsVisible, setUnitsVisible,
                           methodVisible, setMethodVisible,
                           valueListVisible, setValueListVisible,
                           removeVisible, setRemoveVisible, isRepeating
                       }) => {
    const [itemDef, setItemDef] = useState(metaData.item.get(itemRef.itemOID));

    return (
        <>
            {/*라벨인 경우에도 Condition 적용 가능하도록 요청 2025-01-07 #1675*/}
            {/*{itemDef?.controlType !== 'LABEL' &&*/}
            <MandatoryBtn data={itemRef}
                          itemGroupOID={itemGroup.oid}
                          itemRefMap={itemRefMap}
                          setItemRefMap={setItemRefMap}
                          index={index} defType={"item"}/>
            {/*Mandatory 관련 없이 Condition 걸리도록 수정 2025-01-22 #1693*/}
            {
                (!isValueList) &&
                <ConditionBtn metaData={metaData} data={itemRef}
                              itemGroupOID={itemGroup.oid}
                              itemRefMap={itemRefMap}
                              setItemRefMap={setItemRefMap}
                              visible={conditionVisible} setVisible={setConditionVisible}
                              index={index} defType={"item"}/>
            }
            {
                (!isValueList && itemRef?.mandatory === 'YES' && isRepeating === true) &&
                <KeySequenceBtn metadata={metaData} data={itemRef}
                                itemGroupOID={itemGroup.oid}
                                itemRefMap={itemRefMap}
                                setItemRefMap={setItemRefMap}
                                index={index} defType={"item"}/>
            }
            {
                ((!isValueList && isEnableUnit(metaData.item.get(itemRef.itemOID))) && itemDef?.controlType !== 'LABEL' && itemDef?.controlType !== 'DROPDOWN_DATALINK')&&
                <UnitsBtn metaData={metaData} data={itemRef}
                          itemGroupOID={itemGroup.oid}
                          itemRefMap={itemRefMap}
                          setItemRefMap={setItemRefMap}
                          visible={unitsVisible} setVisible={setUnitsVisible}
                          index={index} defType={"item"}/>
            }
            {
                (isRepeating === false) ? (
                    (!isValueList && itemDef?.controlType !== 'LABEL' && itemDef?.controlType !== 'DROPDOWN_DATALINK') &&
                    <MethodBtn metaData={metaData} data={itemRef}
                               itemGroupOID={itemGroup.oid}
                               itemRefMap={itemRefMap}
                               setItemRefMap={setItemRefMap}
                               visible={methodVisible} setVisible={setMethodVisible}
                               index={index} defType={"item"}/>
                ) : (
                    (!isValueList && !itemRef.itemOID.endsWith('TESTCD') && itemDef?.controlType !== 'LABEL' && itemDef?.controlType !== 'DROPDOWN_DATALINK') &&
                    <MethodBtn metaData={metaData} data={itemRef}
                               itemGroupOID={itemGroup.oid}
                               itemRefMap={itemRefMap}
                               setItemRefMap={setItemRefMap}
                               visible={methodVisible} setVisible={setMethodVisible}
                               index={index} defType={"item"}/>
                )
            }
            {itemRef.itemOID.endsWith('TESTCD') &&
                <ValueListBtn metaData={metaData} data={itemRef}
                              itemGroupOID={itemGroup.oid} setIsValueList={setIsValueList}
                              itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                              visible={valueListVisible} setVisible={setValueListVisible}
                              index={index} defType={"item"}/>
            }
            {
                (!isProd && !isExcludeOID(itemRef.itemOID)) &&
                <RemoveBtn itemGroupOID={itemGroup.oid}
                           itemRefMap={itemRefMap}
                           setItemRefMap={setItemRefMap}
                           visible={removeVisible} setVisible={setRemoveVisible}
                           index={index} defType={"item"}/>
            }
        </>
    )
}

const ItemOption = ({isRepeating, designMode, metaData, itemGroup, itemRef, itemRefMap, setItemRefMap, index, isEnableUnit}) => {

    const metaDataStore = useSelector(({metaDataStore}) => metaDataStore);
    const isProd = isEnvProduction(metaDataStore);

    const [conditionVisible, setConditionVisible] = useState(false);
    const [unitsVisible, setUnitsVisible] = useState(false);
    const [methodVisible, setMethodVisible] = useState(false);
    const [valueListVisible, setValueListVisible] = useState(false);
    const [removeVisible, setRemoveVisible] = useState(false);

    const [isValueList, setIsValueList] = useState(false);

    const getVisible = () => {
        return (conditionVisible || unitsVisible || methodVisible || valueListVisible || removeVisible);
    }

    return (
        <>
            {
                //Repeating = NO
                (isRepeating === false)? (
                    <ItemOptionDiv designMode={designMode} visible={getVisible()}>
                        <OptionButtons metaData={metaData} itemRef={itemRef} itemGroup={itemGroup}
                                       itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                       index={index} isValueList={isValueList} setIsValueList={setIsValueList}
                                       isEnableUnit={isEnableUnit} isProd={isProd}
                                       conditionVisible={conditionVisible} setConditionVisible={setConditionVisible}
                                       unitsVisible={unitsVisible} setUnitsVisible={setUnitsVisible}
                                       methodVisible={methodVisible} setMethodVisible={setMethodVisible}
                                       valueListVisible={valueListVisible} setValueListVisible={setValueListVisible}
                                       removeVisible={removeVisible} setRemoveVisible={setRemoveVisible} isRepeating={isRepeating} />
                    </ItemOptionDiv>
                ) : (
                    <RepeatingItemOptionDiv visible={getVisible()}>
                        <OptionButtons metaData={metaData} itemRef={itemRef} itemGroup={itemGroup}
                                       itemRefMap={itemRefMap} setItemRefMap={setItemRefMap}
                                       index={index} isValueList={isValueList} setIsValueList={setIsValueList}
                                       isEnableUnit={isEnableUnit} isProd={isProd}
                                       conditionVisible={conditionVisible} setConditionVisible={setConditionVisible}
                                       unitsVisible={unitsVisible} setUnitsVisible={setUnitsVisible}
                                       methodVisible={methodVisible} setMethodVisible={setMethodVisible}
                                       valueListVisible={valueListVisible} setValueListVisible={setValueListVisible}
                                       removeVisible={removeVisible} setRemoveVisible={setRemoveVisible} isRepeating={isRepeating} />
                    </RepeatingItemOptionDiv>
                )
            }
        </>
    )
}

export default ItemOption;
