import {Link, useNavigate, useParams} from "react-router-dom";
import {Nav, NavItem, NavLink, TabContent} from "reactstrap";
import classNames from "classnames";
import React from "react";
import '../style.scss';
import {getStorageStudy} from "../../../lib/BuilderUtils";

const SettingsTabs = ({children, menu}) => {

    const params = useParams();
    const navigate = useNavigate();
    const studyInfo = getStorageStudy(params.sid);

    return (
        <>
            <Nav tabs className={`settings-tabs`}>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/roles`)}
                             className={classNames({active: menu === 'roles'})}>
                        <i className='fe fe-user mr-2'/>Role
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/roles-privileges`)}
                             className={classNames({active: menu === 'roles-privileges'})}>
                        <i className='fe fe-check-square mr-2'/>Role & Privilege
                    </NavLink>
                </NavItem>
                {/*{*/}
                {/*    (studyInfo?.useRandomization) &&*/}
                {/*    <NavItem>*/}
                {/*        <NavLink onClick={() => navigate(`/studies/${params.sid}/roles-privileges-iwrs`)}*/}
                {/*                 className={classNames({active: menu === 'roles-privileges-iwrs'})}>*/}
                {/*            <i className='fe fe-check-square mr-2'/>Role & Privilege - IWRS*/}
                {/*        </NavLink>*/}
                {/*    </NavItem>*/}
                {/*}*/}
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/roles-learning-course`)}
                             className={classNames({active: menu === 'roles-learning-course'})}>
                        <i className='fe fe-book-open mr-2'/>Role & Learning Course
                    </NavLink>
                </NavItem>
                {/*<NavItem>*/}
                {/*    <NavLink onClick={() => navigate(`/studies/${params.sid}/precondition`)}*/}
                {/*             className={classNames({active: menu === 'precondition'})}>*/}
                {/*        <i className='fa fa-list-ul mr-2'/>Precondition*/}
                {/*    </NavLink>*/}
                {/*</NavItem>*/}
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/crf-setting`)}
                             className={classNames({active: menu === 'crf-setting'})}>
                        <i className='fe fe-book mr-2'/>CRF Setting
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink onClick={() => navigate(`/studies/${params.sid}/email-notice`)}
                             className={classNames({active: menu === 'email-notice'})}>
                        <i className='fa fa-file-text-o mr-2'/>Email Notice
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className={`p-3 content`}>
                {children}
            </TabContent>
        </>
    )
}

export default SettingsTabs
