import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import * as serviceWorker from './serviceWorker';
import './config/lang/i18n';
import {Provider} from "react-redux";
import store from "./store/configureStore";

// themes styles
import './@vb/css/vendors/antd/themes/default.less'
import './@vb/css/vendors/antd/themes/dark.less'

// third-party plugins styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-perfect-scrollbar/dist/css/styles.css'
import 'c3/c3.min.css'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

// vb vendors styles
import './@vb/css/vendors/antd/style.scss'
import './@vb/css/vendors/bootstrap/style.scss'
import './@vb/css/vendors/perfect-scrollbar/style.scss'
import './@vb/css/vendors/nprogress/style.scss'
import './@vb/css/vendors/chartist/style.scss'
import './@vb/css/vendors/chartist-tooltip-plugin/style.scss'
import './@vb/css/vendors/jvectormap/style.scss'
import './@vb/css/vendors/react-sortable-tree/style.scss'
import './@vb/css/vendors/react-draft-wysiwyg/style.scss'

// vb styles
import './@vb/css/core.scss'
import './@vb/css/measurements.scss'
import './@vb/css/colors.scss'
import './@vb/css/utils.scss'
import './@vb/css/layout.scss'
import './@vb/css/router.scss'

// vb extra styles
import './@vb/css/extra/clean.scss' // clean styles
import './@vb/css/extra/air.scss'

import {LoadingSpinnerComponent} from "./components/common/LoadingSpinerComponent";
import App from "./App";
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";

// devExtreme Styles
import 'devextreme/dist/css/dx.light.css'
import 'devextreme/dist/css/dx.common.css'

//antd
import {ConfigProvider} from 'antd';
import {QueryClient, QueryClientProvider} from "react-query";

// 추가옵션 넣을 수 있음
const helmetContext = {}

const queryClient = new QueryClient();

const rootNode = createRoot(document.getElementById('root'));
rootNode.render(
    // react-helmet -> helmet : title 변경 용도로 사용
    <HelmetProvider context={helmetContext}>
        {/*<React.StrictMode>*/}
        <QueryClientProvider client={queryClient}>
            <ConfigProvider theme={{hashed: false}}>
                {/*실행*/}
                <Provider store={store}>
                    {/*<ThemeProvider theme={theme}>*/}
                    {/* browserRouter : 기존 url이랑 동일하게 설정된 것*/}
                    {/* hashRouter : url에 #이 들어감, hash 뒤에 들어가는 링크는 query-string으로 분류*/}
                    <BrowserRouter>
                        {/*보라색 ... 로딩화면*/}
                        <LoadingSpinnerComponent/>
                        <App/>
                    </BrowserRouter>
                    {/*</ThemeProvider>*/}
                </Provider>
            </ConfigProvider>
        </QueryClientProvider>
        {/*</React.StrictMode>*/}
    </HelmetProvider>
)


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
