import {Outlet, useParams} from "react-router-dom";
import React from "react";
import useLibraryStore from "../../../../store/libraryStore";
import {useQuery} from "react-query";
import PrimaryLoading from "../../../../components/common/PrimaryLoading";
import {getGlobalLibraryWithAxios} from "../../../../lib/api/library";

const LibraryPage = () => {
    const params = useParams();
    const {selectLibrary, loaded} = useLibraryStore(state => (state));
    const query = useQuery('library', () => getGlobalLibraryWithAxios(params?.libraryId), {
        staleTime: 5000, // 현재 데이터가 신선한 데이터인지 판정하는 시간 설정 (가장 최근의 데이터를 보여줌)
        cacheTime: '5m', // 현재 데이터를 Cache로 사용할지에 대한 설정 (5분 이내의 데이터는 지금 데이터를 보여줌)
        retry: 0, //실패 시, 재호출 횟수
        refetchOnWindowFocus: false, //사용자가 사용하는 윈도우가 다른곳에 갔다가 다시 Focus를 얻을 때 Refetch를 할지 설정
        onSuccess: (data) => {
            //성공 시 호출
            console.log('Library 정보 조회 요청...', params);
            //Library Setting - Zustand에 포함
            selectLibrary(data);
            console.log("Data : ", data);
        },
        onError : err => {
            // 실패시 호출 (401, 404 같은 error가 아니라 정말 api 호출이 실패한 경우만 호출됩니다.)
            // 강제로 에러 발생시키려면 api단에서 throw Error 날립니다. (참조: https://react-query.tanstack.com/guides/query-functions#usage-with-fetch-and-other-clients-that-do-not-throw-by-default)
            console.log(err.message);
        }
    });

    return (
        <>
            {
                (!query?.isLoading && loaded)?(
                    <Outlet/>
                ):(
                    <PrimaryLoading />
                )
            }
        </>
    )

}

export default LibraryPage;